h1, h2 {
    font-weight: bold;
}

.counter span {
    display: inline-block;
    background-color: $color;
    color: white;
    text-align: center;
    margin-right: 3px;
    width: 30px;
    height: 100%;
    padding-top: 0.5rem;
}

dt, dd {
    display: inline;
}

dd span {
  vertical-align: middle;
}

.btn, input {
    display: inline-block;
    height: 100%;
    padding: 0 10px;
    border: 4px solid $color-dark-grey;
    transition-duration: 0.4s;
    font-size: 1rem;
    font-family: 'Fivo Sans';
    color: $color-dark-grey;
    background-color: $color-grey;
    white-space: normal;
    position: relative;
    z-index: 999;
  &:hover, &:active {
      color: $color-grey;
      background-color: $color-dark-grey;
      cursor: pointer;
    }
    &:active {
        transform: translateY(8px);
    }
}

.simple {
    @include flex(column, $justify: center);
}

time {
    text-transform: capitalize;
}

.clickable {
    cursor: pointer;
}
