@import 'map';

main, section:not(:last-of-type) {
    border: $border-width solid $border-color;
}
@media (max-width: $xl) {
    main, section:first-of-type {
        border-bottom: none;
    }
    section {
        border-top: none;
    }
}

main, section:first-of-type {
    width: 100%;
    display: grid;
    background-color: $color;
    grid-template-columns: repeat(6, calc(100% / 6));
    height: auto;

    & > *:not(#arrow) {
        background-color: white;
    }

    & > *:not(#arrow):not(#shroud-component):not(#infos-component) {
        border: $border-width solid $border-color;
    }

    & > #infos-component {
        border-top: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
    }

    // Draw border bottom on each child box except the last one
    & > div > div:not(:last-of-type) {
        border-bottom: $border-width * 2 solid $border-color;
    }
}

main {
    grid-template-rows: repeat(47, 75px);
    grid-template-areas :
    'title    title    title    title    author   author   '
    'title    title    title    title    author   author   '
    'textc    textc    textc    textc    textc    textc    '
    'head     head     head     head     head     head     '
    'map      map      map      map      map      map      '
    'map      map      map      map      map      map      '
    'map      map      map      map      map      map      '
    'map      map      map      map      map      map      '
    'map      map      map      map      map      map      '
    'map      map      map      map      map      map      '
    'tkm      tkm      tkm      tkm      tkm      tkm      '
    'time     time     time     time     time     time     '
    'texta    texta    texta    texta    texta    texta    '
    'clubs    clubs    clubs    clubs    clubs    clubs    '
    'graph    graph    graph    graph    graph    graph    '
    'graph    graph    graph    graph    graph    graph    '
    'graph    graph    graph    graph    graph    graph    '
    'schem    schem    schem    schem    schem    schem    '
    'schem    schem    schem    schem    schem    schem    '
    'schem    schem    schem    schem    schem    schem    '
    'schem    schem    schem    schem    schem    schem    '
    'schem    schem    schem    schem    schem    schem    '
    'schem    schem    schem    schem    schem    schem    '
    'week     week     week     week     week     week     '
    'week     week     week     week     week     week     '
    'duo      duo      duo      duo      duo      duo      '
    'duo      duo      duo      duo      duo      duo      '
    'duo      duo      duo      duo      duo      duo      '
    'duo      duo      duo      duo      duo      duo      '
    'speed    speed    speed    speed    speed    speed    '
    'textb    textb    textb    textb    textb    textb    '
    'user     user     user     user     user     user'
    'loc      loc      loc      loc      loc      loc      '
    'loc      loc      loc      loc      loc      loc      '
    'loc      loc      loc      loc      loc      loc      '
    'loc      loc      loc      loc      loc      loc      '
    'album    album    album    album    album    album    '
    'album    album    album    album    album    album    '
    'album    album    album    album    album    album    '
    'album    album    album    album    album    album    '
    'album    album    album    album    album    album    '
    'infos    infos    infos    infos    infos    infos    '
    'misc     misc     misc     misc     misc     misc    '
    'misc     misc     misc     misc     misc     misc    '
    'misc     misc     misc     misc     misc     misc    '
    'misc     misc     misc     misc     misc     misc    '
    'misc     misc     misc     misc     misc     misc    ';

    #compass > div {
        border: $border-width * 2 solid $border-color;
    }
}

section:first-of-type {
    grid-template-rows: repeat(4, 75px);
    grid-template-areas:
    'shroud shroud shroud shroud shroud shroud'
    'shroud shroud shroud shroud shroud shroud'
    'shroud shroud shroud shroud shroud shroud'
    'shroud shroud shroud shroud shroud shroud';

    border-top: none;

    & > #shroud-component {
        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
    }
}

@media (min-width: $xl) {
    main {
        grid-template-columns: repeat(11, calc(100% / 11));
        grid-template-rows: repeat(20, calc(100vh / 20));
        height: 100vh;

        & > #infos-component {
            border: $border-width solid $border-color;
        }
    }
    section:first-of-type {
        grid-template-columns: repeat(9, calc(100% / 9));
        grid-template-rows: repeat(6, calc(30vh / 6));
        height: 30vh;

        & > #shroud-component {
            border: $border-width solid $border-color;
        }
    }

    main {
        grid-template-areas:
        'title  title  title  author head   head   head   week   week   week   week '
        'title  title  title  author head   head   head   week   week   week   week '
        'title  title  title  author head   head   head   week   week   week   week '
        'tkm    tkm    tkm    tkm    map    map    map    map    map    texta  texta'
        'time   time   time   schem  map    map    map    map    map    duo    duo  '
        'clubs  clubs  clubs  schem  map    map    map    map    map    duo    duo  '
        'graph  graph  graph  schem  map    map    map    map    map    duo    duo  '
        'graph  graph  graph  schem  map    map    map    map    map    duo    duo  '
        'graph  graph  graph  schem  map    map    map    map    map    duo    duo  '
        'graph  graph  graph  schem  map    map    map    map    map    speed  speed'
        'graph  graph  graph  schem  map    map    map    map    map    textb  textb'
        'graph  graph  graph  schem  map    map    map    map    map    album  album'
        'graph  graph  graph  schem  map    map    map    map    map    album  album'
        'user   user   user   schem  map    map    map    map    map    album  album'
        'loc    loc    textc  textc  map    map    map    map    map    album  album'
        'loc    loc    misc   misc   map    map    map    map    map    album  album'
        'loc    loc    misc   misc   map    map    map    map    map    album  album'
        'loc    loc    misc   misc   map    map    map    map    map    album  album'
        'loc    loc    misc   misc   map    map    map    map    map    album  album'
        'infos  infos  infos  infos  infos  infos  infos  infos  infos  infos  infos';
    }

    section:first-of-type {
        border-top: $border-width solid $border-color;
        grid-template-areas:
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud '
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud '
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud '
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud '
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud '
        'shroud   shroud   shroud   shroud  shroud  shroud  shroud  shroud  shroud ';
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    main {
        grid-template-rows: repeat(48, 75px);
        grid-template-areas :
        'title    title    title    title    title    title    '
        'title    title    title    title    title    title    '
        'author   author   author   author   author   author   '
        'textc    textc    textc    textc    textc    textc    '
        'head     head     head     head     head     head     '
        'map      map      map      map      map      map      '
        'map      map      map      map      map      map      '
        'map      map      map      map      map      map      '
        'map      map      map      map      map      map      '
        'map      map      map      map      map      map      '
        'map      map      map      map      map      map      '
        'tkm      tkm      tkm      tkm      tkm      tkm      '
        'time     time     time     time     time     time     '
        'texta    texta    texta    texta    texta    texta    '
        'clubs    clubs    clubs    clubs    clubs    clubs    '
        'graph    graph    graph    graph    graph    graph    '
        'graph    graph    graph    graph    graph    graph    '
        'graph    graph    graph    graph    graph    graph    '
        'schem    schem    schem    schem    schem    schem    '
        'schem    schem    schem    schem    schem    schem    '
        'schem    schem    schem    schem    schem    schem    '
        'schem    schem    schem    schem    schem    schem    '
        'schem    schem    schem    schem    schem    schem    '
        'schem    schem    schem    schem    schem    schem    '
        'week     week     week     week     week     week     '
        'week     week     week     week     week     week     '
        'duo      duo      duo      duo      duo      duo      '
        'duo      duo      duo      duo      duo      duo      '
        'duo      duo      duo      duo      duo      duo      '
        'duo      duo      duo      duo      duo      duo      '
        'speed    speed    speed    speed    speed    speed    '
        'textb    textb    textb    textb    textb    textb    '
        'user     user     user     user     user     user'
        'loc      loc      loc      loc      loc      loc      '
        'loc      loc      loc      loc      loc      loc      '
        'loc      loc      loc      loc      loc      loc      '
        'loc      loc      loc      loc      loc      loc      '
        'album    album    album    album    album    album    '
        'album    album    album    album    album    album    '
        'album    album    album    album    album    album    '
        'album    album    album    album    album    album    '
        'album    album    album    album    album    album    '
        'infos    infos    infos    infos    infos    infos    '
        'misc     misc     misc     misc     misc     misc    '
        'misc     misc     misc     misc     misc     misc    '
        'misc     misc     misc     misc     misc     misc    '
        'misc     misc     misc     misc     misc     misc    '
        'misc     misc     misc     misc     misc     misc    ';

    }
    section:first-of-type {
        border-top: none;
        grid-template-rows: repeat(4, 75px);
        grid-template-areas:
        'shroud shroud shroud shroud shroud shroud'
        'shroud shroud shroud shroud shroud shroud'
        'shroud shroud shroud shroud shroud shroud'
        'shroud shroud shroud shroud shroud shroud';
    }
}

section:last-of-type {
    padding: 10px;
    text-align: center;
    dl, dl a {
    display: inline;
    }
    dl a {
        color: $alt-color;
    }
    #show-more {
        display: inline;
        &.hide {
            display: none;
        }
    }
    input {
        margin-left: 10px;
        &.hide {
            display: none;
        }
    }
}

.box-col {
    @include flex(column, $justify: space-between);
    & > div {
        @include flex(row, $align: center);
        height: 100%;
    }
}

.pad-left {
    padding-left: 10px;
}
.pad-right {
    padding-right: 10px;
}
.pad {
    padding: 10px;
}

#title-component {
    grid-area: title;
    & > div {
        p {
            line-height: 1.25rem;
        }
        @include flex(column, $justify: center, $align: center);
        * {
            text-align: center;
        }
        @media (min-width: $xl) {
            align-items: flex-start;
            * {
                text-align: left;
            }
        }
        h1 {
            a {
                font-size: 4rem;
            }
        }
        p {
            font-size: 1.25rem;
        }
        @media (min-width: $xl) and (max-width: 1300px) {
            h1 a {
                font-size: 2.5rem;
            }
            p {
                font-size: 1rem;
            }
        }
        @media (min-width: 1300px) and (max-width: 1680px), (max-width: 767px) {
            h1 a {
                font-size: 3rem;
            }
            p {
                font-size: 1rem;
            }
        }
    }
}

#author-component {
    grid-area: author;
    & > div {
        @include flex(row, $justify: center, $align: center);
    }
    a {
        font-size: 1.75rem;
        @media (min-width: $xl) and (max-width: 1680px), (max-width: 767px) and (orientation: portrait) {
            font-size: 1.25rem;
        }
    }
}

#head-component {
    grid-area: head;
    @include flex(row, $justify: center, $align: center);
    h2 {
        font-size: 2rem;
        @media (max-width: 767px) and (orientation: portrait) {
            font-size: 1.5rem;
        }
    }
}

#header-component {
    grid-area: header;
    @media (max-width: $xl) {
        @include flex(row, $justify: space-around, $align: center);
        div {
            width: 50%;
            justify-content: center;
        }
        div:first-of-type {
            border-bottom: none;
            border-right: $border-width * 2 solid $border-color;
        }
    }
    @media (max-width: 767px) and (orientation: portrait) {
        div {
            padding: 10px;
        }
        div:first-of-type {
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

#week-component {
    grid-area: week;
    & > div:first-of-type {
        justify-content: space-between;
    }
    h2 {
        font-size: 2.5rem;
        @media (min-width: $xl) and (max-width: 1680px), (max-width: 767px) {
            font-size: 2rem;
        }
    }
    h3 {
        font-size: 2rem;
        @media (min-width: $xl) and (max-width: 1680px), (max-width: 767px) {
            font-size: 1.5rem;
        }
    }
}

#km-total-component, #km-week-component {
    & > div {
        height: 100%;
    }
    dl {
        height: 100%;
        @include flex(row, $align: center);
        flex-wrap: wrap;
        @media (min-width: $xl) {
            flex-wrap: nowrap;
        }
    }
    dd {
        font-size: 2.5rem;
        margin-left: 3px;
    }
}

#km-total-component {
    grid-area: tkm;
    @media (min-width: $xl) and (max-width: 1530px) {
        dt {
            width: 40%;
        }
        dd {
            width: 60%;
        }
    }
}

#km-week-component {
    grid-area: wkm;
    @media (min-width: $xl) and (max-width: 1530px) {
        dt {
            width: 30%;
            hyphens: auto;
        }
        dd {
            width: 70%;
        }
    }
}


#timeline-component {
    grid-area: time;
    @include flex(row, $align: center);
    width: 100%;
    #travel {
        width: 100%;
        height: 100%;
        padding: 10px;
        span {
            width: 0%;
            height: 100%;
            &::after {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
    & > p {
        right: 0;
        padding-right: 20px;
    }
}

#map-component {
    grid-area: map;
    #map {
        height: 100%;
        #compass-container {
            @include flex(row, $justify: center, $align: center);
            border-left: $border-width * 2 solid $border-color;
            border-bottom: $border-width * 2 solid $border-color;
            width: 100px;
            height: 100px;
            right: 0;
        }
        #compass {
            & > div {
                width: 60px;
                height: 60px;
            }
        }
    }
}

#duo-component {
    grid-area: duo;
    & > div {
        height: 50%;
    }
    div > div {
        &:first-of-type {
            width: 25%;
            height: 100%;
        }
        &:last-of-type {
            width: 75%;
        }
    }
    img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    h4, p, dt, dd {
        font-size: 1.25rem;

        @media (min-width: $xl) and (max-height: 900px) {
            font-size: 1rem;
        }
    }
    dt {
        line-height: 1.25rem;
    }
    #juggling, #guest {
        align-items: flex-start;
    }
    #guest {
        dl {
            @include flex(row);
        }
    }
}

.marquee {
    width: 100%;
    p {
        padding-left: 100%;
        span.line-through {
            text-decoration: line-through;
        }
    }
}

.banner p {
    font-size: 1.5rem;
}

#texta p, #textd p {
    padding-left: -100%;
    animation: marquee-reverse 15s linear infinite;
}

#texta {
    grid-area: texta;
}
#textb {
    grid-area: textb;
}
#textc {
    grid-area: textc;
}

#textd {
    grid-area: textd;
}

#shroud-component {
    grid-area: shroud;
    height: 100%;
    display: flex;
    figure {
        // @include flex(row, $justify: space-between, $align: center);
        margin: 10px;
    }
    img {
        height: 50px;
        height: 175px;
    }
    figcaption {
        margin-top: 5px;
        text-align: center;
        // margin-left: 5px;
    }
    @media (min-width: $xl) {
        figure {
            // @include flex(column, $justify: center, $align: center);
            margin: 20px 10px;
        }
        // figcaption {
        //     margin-top: 5px;
        //     margin-left: 0;
        // }
    }
}


#msg-component {
    position: relative;
    top: 0;
    right: 0;
    height: 100%;
    grid-area: msg;
    .btn {
        width: 100%;
    }
}

#clubs-component {
    grid-area: clubs;
    dl {
        @include flex(row, $justify: flex-start, $align: center);
    }
    dt {
        margin-left: 10px;
    }
    dd {
        margin-right: 10px;
        font-size: 2.5rem;
    }
    @media (min-width: $xl) and (max-width: 1530px) {
        dt {
            width: 45%;
        }
        dd {
            width: 55%;
        }
    }
}

#speed-component {
    grid-area: speed;
    & > div div:first-of-type {
        overflow: hidden;
        display: flex;
        align-items: baseline;
        dt {
            white-space: nowrap;
        }
    }
}

#graph-component {
    grid-area: graph;
}

#user-location-component, #location-component {
    span {
        font-size: 2rem;
        @media (min-width: $xl) and (max-width: 1620px) {
            font-size: 1.25rem;
        }
    }
    .row {
        @media (min-width: $xl) {
            height: calc(100vh / 20);
        }
    }
}

#user-location-component {
    grid-area: user;
    & > div, & > div > div {
        @include flex(row, $align: center);
    }
    .row {
        height: 100%;
    }
}

#location-component {
    grid-area: loc;
    max-height: 100%;
    @include flex(column);
    #small-map {
        width: 100%;
        height: 100%;
    }
    #satellite {
        @include flex(row, $align: center);
    }
}

#misc-component {
    grid-area: misc;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

#week-imgs-component {
    grid-area: album;
    figure {
        @include flex(column);
        max-width: 100%;
        height: 100%;
    }
    img, video {
        width: 100%;
        height: 100%;
        // height: 90%;
    }
    div {
        height: 10%;
        padding: 0 20px 0 10px;
    }
}

#infos-component {
    grid-area: infos;
    @include flex(row, $align: center);
    & > div {
        height: 100%;
        @include flex(row, $justify: center, $align: center);
    }
    h1 {
        padding: 10px;
    }
    & > div:first-of-type {
        width: 15%;
        @media (min-width: $xl) {
            width: 7%;
        }
        @media (max-width: 767px) and (orientation: portrait) {
            width: 30%;
        }
    }
    & > div:last-of-type {
        width: 85%;
        @media (min-width: $xl) {
            width: 93%;
        }
        @media (max-width: 767px) and (orientation: portrait) {
            width: 70%;
        }
    }
    .marquee {
        p {
            font-size: 2rem;
        }
    }
}

#schema {
    grid-area: schem;
    width: 100%;
    @include flex(column, $justify: center, $align: center);
    .scene {
        perspective: 800px;
        width: 100%;
        height: 100%;
        .card {
            width: 100%;
            height: 100%;
            @media (min-width: $xl) {
                background-color: white;
            }
        }
        .cardface {
            z-index: 999;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
}

#post {
    grid-area: post;
    display: block;
    ul {
        margin: 0;
        padding: 0;
    }
    & > div {
        // height: 100%;
        border: $border-width solid $border-color;
    }
    #post-head h4, #post-head h5, #post-head p, .wandering-post-link, .content-container h5 {
        font-size: 1.5rem;
    }
    #wanderings {
        width: 100%;
        ul {
            li:not(:last-of-type) {
                border-bottom: $border-width * 2 solid $border-color;
            }
            h4 {
                font-size: 1.5rem;
                @media (min-width: $xl) and (max-width: 1480px) {
                    text-align: center;
                }
            }
        }
    }
    #post-container {
        width: 100%;
    }
    #post-head {
        @include flex(column);
        border-bottom: $border-width * 2 solid $border-color;

        & > div {
            width: 100%;
        }
        & > div:first-of-type {
            border-bottom: $border-width * 2 solid $border-color;
            border-right: none;
        }
        & > div:last-of-type {
            @include flex(column);
            div {
                width: 100%;
                &:not(:first-of-type) {
                    border-left: none;
                }
            }
        }
    }
    @media (min-width: $xl) {
        @include flex(row, $justify: space-between);
        align-items: stretch;

        #wanderings {
            width: 10%;
        }
        #post-container {
            width: 90%;
        }
    }
    @media (min-width: $lg) {
        #post-head {
            @include flex(row, $justify: space-between);
            & > div:first-of-type {
                width: 30%;
                border-right: $border-width * 2 solid $border-color;
                border-bottom: none;
            }
            & > div:last-of-type {
                @include flex(row);
                width: 70%;
                & > div:not(:first-of-type) {
                    border-left: $border-width * 2 solid $border-color;
                }
            }
        }
    }
    #post-body {
        ul {
            width: 100%;
        }
        & > ul > li:not(:first-of-type) {
            border-top: $border-width * 2 solid $border-color;
        }
        .content-container, .content {
            border-top: $border-width * 2 solid $border-color;
        }
        @media (min-width: $xl) {
            & > ul > li:last-of-type {
                border-bottom: $border-width * 2 solid $border-color;
            }
        }
        .content {
            display: block;
            height: auto;
            .text {
                width: 100%;
                border-right: none;

                p, h1, h2, h3, ul, ol {
                    margin-bottom: 20px;
                    line-height: 1.25rem;
                }
                h2 {
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    font-weight: normal;
                }
                h3 {
                    font-size: 1.25rem;
                }
                h4 {
                    font-size: 1rem;
                }
                ul {
                    list-style-type: disc;
                }
                ol, ul {
                    padding-left: 1rem;
                }
                i, em, blockquote {
                    font-style: italic;
                }
                strong {
                    font-weight: bold;
                }
                a {
                    color: $alt-color;
                }
            }
            @media (min-width: $lg) {
                @include flex(row);
                height: 500px;
                .text {
                    width: 30%;
                    border-right: $border-width * 2 solid $border-color;
                }
            }

            .gallery {
                display: block;
                height: auto;
                width: 100%;
                figure {
                    @include flex(column);
                    height: 100%;
                    // padding-bottom: 10px;
                    &:first-of-type {
                        border-top : $border-width * 2 solid $border-color;
                    }
                }
                img, video {
                    // max-height: 90%;
                    max-height: 100%;
                    max-width: 100%;
                }
                // figcaption {
                //     height: 10%;
                //     padding: 10px;
                // }
                @media (min-width: $lg) {
                    @include flex(row);
                    height: 100%;
                    width: 70%;
                    figure {
                        &:first-of-type {
                            border-top : none;
                        }
                    }
                    img, video {
                        max-height: none;
                        max-width: none;
                        // height: 90%;
                        height: 100%;
                        width: auto;
                    }
                }
            }

            .full-width {
              width: 100%;
              border-right: none;
            }
        }
    }
}

#qrcode {
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(15vh - #{$border-width * 2});
  border: $border-width * 2 solid $border-color;
  z-index: 1000;
}
