@font-face {
  font-family: 'Fivo Sans';
  src: url('../../fonts/fivo_sans_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans';
  src: url('../../fonts/fivo_sans_bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inria Serif';
  src: url('../../fonts/inria_serif_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inria Serif';
  src: url('../../fonts/inria_serif_italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inria Serif';
  src: url('../../fonts/inria_serif_bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inria Serif';
  src: url('../../fonts/inria_serif_bold_italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
