$color: black;
$alt-color: red;
$color-grey: #e7e7e7;
$color-yellow: #fff380;
$color-blue: #addfff;
$color-dark-grey: #333333;
$border-color: $color;
$border-width: 2px;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
