@import 'base/variables';
@import 'home/reset';
@import 'home/mixins';

@import 'home/global';
@import 'home/components';
@import 'home/home';

@import 'base/fonts';

:root {
    font-family: 'Fivo Sans';
    font-size: 1em;
}

html, body {
    margin: 0;
    padding: 0;
}

*[hidden] {
  display: none !important;
}
