body {
    overflow-x: hidden;
}

#title-component, #author-component {
    a {
        color: black;
        &:hover {
            color: $alt-color;
        }
    }
}

#title-component {
    h1 {
        a {
            text-transform: uppercase;
        }
    }
    p {
        font-family: 'Inria Serif'
    }
}

#author-component {
    a {
        text-align: center;
        color: black;
        @media (max-width: 767px) and (orientation: portrait) {
            text-align: left;
        }
    }
}

#head-component {
    h2 {
        text-transform: uppercase;
        text-align: center;
    }
}

#header-component {
    div {
        p {
            color: white;
            background-color: $alt-color;
            border-radius: 25px;
            // animation: blink 1.5s linear infinite;
        }
    }
    & > div:last-of-type time {
        text-align: center;
    }
}

@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

#week-component {
    h2 {
        color: $alt-color;
        text-shadow: 2px 2px $color;
    }
    h3 {
        font-family: 'Inria Serif';
        font-weight: normal;
    }
}

#km-total-component, #km-week-component {
    & > div {
        overflow: hidden;
    }
}

#timeline-component {
    position: relative;
    #travel {
        position: relative;
        background: #333333;
        span {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 20px;
            box-shadow: 2px 2px 5px black;
            background-color: white;
            background-image: linear-gradient(to bottom, white, $alt-color);
            &::after {
                animation: load 1s ease-in-out infinite;
                content: "";
                position: absolute;
                background-image: linear-gradient(
                -45deg,
                rgba(255, 255, 255, .2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, .2) 50%,
                rgba(255, 255, 255, .2) 75%,
                transparent 75%,
                transparent
                );
                z-index: 1;
                background-size: 50px 50px;
                border-radius: 20px;
                overflow: hidden;
            }
        }
    }
    & > p {
        position: absolute;
        color: white;
        text-shadow: 2px 2px black;
        font-weight: bold;
        z-index: 999;
    }
}

@keyframes load {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

#duo-component > div {
    background-color: $color-yellow;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    p {
        display: inline-block;
        /* show the marquee just outside the paragraph */
        will-change: transform;
        animation: marquee 15s linear infinite;
    }
}

#textb, #textd {
    p {
        color: white;
    }
}
#textb {
    background-color: $color !important;
    .marquee {
        p {
            animation: marquee 30s linear infinite;
        }
    }
}
#textd {
    background-color: $color-dark-grey !important;
}

@keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

@keyframes marquee-reverse {
    0% { transform: translate(-100%, 0); }
    100% { transform: translate(0, 0); }
}

#shroud-component {
    overflow-y: scroll;
    @media (max-width: $xl) {
        border-bottom: none;
    }

    figcaption {
        text-align: right;
        @media (min-width: $xl) {
            text-align: center;
        }
    }

}

#msg-component {
    .btn {
        text-align: center;
    }
}

#clubs-component {
    dd {
        vertical-align: middle;
    }
}

#graph-component {
    svg {
        height: 100%;
        width: 100%;
        background-color: $color-grey;

        * {
            vector-effect: non-scaling-stroke;
        }

        text {
            fill: $color;
            font-size: .8rem;
            dominant-baseline: middle;
            text-anchor: middle;
        }

        .texty text {
            text-anchor: end;
        }

        .line {
            stroke: $color;
            stroke-width: 2px;
        }

        .line-dot {
            stroke: grey;
            stroke-dasharray: 2;
            stroke-width: 1px;
        }

        .legendx {
            transform-origin: 0 0;
            transform: rotate(90deg);
        }

        rect {
            fill: $alt-color;
        }

        .curve {
            stroke: $alt-color;
            fill: none;
            stroke-width: 4px;
            stroke-linecap: round;
        }
    }
}

#location-component {
    dt {
        text-transform: capitalize;
    }
    dd {
        vertical-align: middle;
    }
    #small-map {
        background-color: $color-grey;
    }
}

#misc-component {
    & > div:first-of-type {
        border-bottom: $border-width * 2 solid $border-color;
        & > div:first-of-type img {
            animation: rotate 10s linear infinite;
        }
        & > div:last-of-type {
            background-color: $color-blue;
        }
    }
    p {
        text-align: center;
    }
}

@keyframes rotate {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
}

#week-imgs-component {
    figure {
        background-color: $color;
    }
    img, video {
        object-fit: contain;
        object-position: center;
    }
    figcaption {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#infos-component {
    marquee > div {
        border-right: $border-width solid $border-color;
        display: inline-block;
    }
    & > div:first-of-type {
        background-color: $color;
    }
    & > div:last-of-type {
        background-color: white;
    }
    h1 {
        color: white;
    }
}

#ceremony {
    & > div:first-of-type {
        background-color: $alt-color;
        border-bottom: none;
        border-right: $border-width * 2 solid $border-color;
        h4 {
            text-align: center;
        }
    }
    @media (min-width: $xl) {
        & > div:first-of-type {
            border-right: none;
            border-bottom: $border-width * 2 solid $border-color;
        }
    }
}

#program {
    overflow-y: auto;
    background-color: $color-yellow !important;
}

#schema {
    background-color: $color;
    h4 {
        background-color: white;
        text-align: center;
        font-weight: normal;
    }
    .card {
        position: relative;
        // transform-style: preserve-3d;
        // animation-name: hide;
        // animation-duration: 5s;
        // animation-timing-function: linear;
        // animation-iteration-count: infinite;
        // animation-direction: normal;
    }
    .cardface {
        position: absolute;
        // backface-visibility: hidden;

        // to remove to get previous anim
        animation-name: hide;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: normal;
        & ~ img {
            transform: scaleX(-1);
        }
    }
}

@keyframes hide {
    0%, 50% { opacity: 1 }
    51%, 100% { opacity: 0 }
}

@keyframes flip {
    0% { transform: rotateY(0deg); }
    25% { transform: rotateY(0deg); }
    50% { transform: rotateY(180deg); }
    75% { transform: rotateY(180deg); }
    100% { transform: rotateY(360deg); }
}

#post {
    ul {
        list-style: none;
    }
    h5, .content > div:first-of-type {
        font-family: 'Inria Serif';
    }

    #wanderings {
        a {
            color: $color;
            cursor: pointer;
        }
        li {
            &.opened {
                a h4 {
                    color: white;
                    background-color: black;
                }
            }
            &:hover, &:focus {
                a h4 {
                    color: white;
                    background-color: black;
                }
            }
        }
    }
    #post-head {
        & > div {
            text-align: center;
            div {

            }
        }
        .picto {
            text-align: center;
        }
        h5 {
            font-weight: normal;
        }
    }

    #post-body {
        li {
            &.handover-post .wandering-post-link {
                background-color: $alt-color;
            }
            .wandering-post-link {
                text-align: center;
                display: block;
                width: 100%;
                color: $color;
                &:hover {
                    color: white;
                    background-color: black;
                }
            }
            &:not(.handover-post).wandering-post-link {
                &:focus {
                    color: white;
                    background-color: black;
                }
            }
            &:not(.handover-post).opened .wandering-post-link {
                background-color: $color;
                color: white;
            }
        }
        .content .text {
            overflow-y: auto;
        }
        .content .gallery {
            overflow-y: auto;
            overflow-x: hidden;
            @media (min-width: $lg) {
                overflow-x: auto;
                overflow-y: hidden;
            }
        }
        .content .video {
          width: 100%;
          @media (min-width: 640px) {
              min-width: 640px;
          }
        }
    }

    li:not(.opened) .content-container {
        display: none;
    }
}

.popup-container {
    display: none;
}

.with-popup {

    .popup-container {
        position: sticky;
        background-color: #ffffff75;
        margin-top: -100vh;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        top: 0;
        padding: 5rem;

        @media (max-width: 767px) {
            padding: 1rem;
        }

        &, * {
            box-sizing: border-box;
        }

        .popup {
            position: relative;
            border: $border-width * 2 solid $border-color;
            padding: 2rem;
            background-color: white;
            max-width: 100%;
            max-height: 100%;
            overflow: auto;

            .cross {
                position: absolute;
                top: 0;
                right: 0;
                height: 2rem;
                width: 2rem;
                line-height: 1.75rem;
                text-align: center;
                border-left: $border-width * 2 solid $border-color;
                border-bottom: $border-width * 2 solid $border-color;

                &:hover {
                    cursor: pointer;
                }
            }

            p {
                margin-bottom: 1rem;
            }
            p {
                line-height: 1.25rem;
            }

            h2 {
                margin-bottom: 1rem;
            }


            img, video {
                display: block;
                max-height: calc(100vh - 14rem - 4px);
                max-width: calc(100vw - 14rem - 4px);
                @media (max-width: 767px) {
                    max-height: calc(100vh - 6rem - 4px);
                    max-width: calc(100vw - 6rem - 4px);
                }
            }
        }
    }

    &.popup-project #popup-project, &.popup-img #popup-img, &.popup-live #popup-live {
        @include flex(row, $align: center, $justify: center);
    }
}

#popup-project {
    @media (min-width: $md) {
        .popup {
            padding: 3rem;
        }
    }
    p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-align: center;
        &:not(:last-of-type) {
            margin-bottom: 2.5rem;
        }
    }
}

#arrow {
    position: absolute;
    height: 100%;
    display: none;
    right: 0;
    z-index: 1000;
    p {
        font-family: sans-serif;
        font-size: 10rem;
        color: $alt-color;
    }
}

.jump {
    @media (min-width: $xl) {
        display: flex !important;
        align-items: center;
    }
    animation: jump 5s linear;
    animation-fill-mode: forwards;
}

@keyframes jump {
    0% {
        bottom: 0;
    }
    10% {
        bottom: 0;
    }
    20% {
        bottom: -100px;
    }
    30% {
        bottom: 100px;
    }
    40% {
        bottom: -100px;
    }
    50% {
        bottom: 100px;
    }
    60% {
        bottom: -100px;
    }
    70% {
        bottom: 100px;
    }
    80% {
        bottom: -100px;
    }
    90% {
        bottom: 0;
        visibility: visible;
    }
    100% {
        bottom: 0;
        visibility: hidden;
    }
}
