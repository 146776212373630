@import 'leaflet';

#map {
    #compass-container {
        position: absolute;
        z-index: 999;
        background-color: white;
    }
    #compass {
        position: absolute;
        z-index: 999;
        background-color: white;
        border-radius: 50px;
        p {
            font-weight: bold;
        }
        & > div {
            z-index: 999;
            border-radius: 50px;
            @include flex(row, $justify: center, $align: center);
            & > div {
                position: absolute;
                // N-O-S-E letters positionning
                &:first-child, &:nth-child(2) {
                    width: 100%;
                    text-align: center;
                }
                &:first-child {
                    top: 0;
                    margin-top: 5px;
                }
                &:nth-child(2) {
                    bottom: 0;
                    margin-bottom: 3px;
                }
                &:nth-child(3), &:nth-child(4) {
                    height: 100%;
                    @include flex(row, $align: center);
                }
                &:nth-child(3) {
                    left: 0;
                    margin-left: 7px;
                }
                &:nth-child(4) {
                    right: 0;
                    margin-right: 7px;
                }
            }
        }
    }
    .leaflet-control-zoom {
        @include flex(row);
        border: none;
        margin: 0;
        a {
            @include flex(row, $justify: center, $align: center);
            height: 30px;
            width: 30px;
            box-sizing: content-box;
            border: $border-width * 2 solid $border-color;
            border-right: none;
            border-bottom: none;
            border-radius: 0;
            @media (min-width: $xl) {
                height: 3vh;
                width: 3vh;
            }
        }
    }

    .leaflet-control-attribution {
      display: none;
    }

    svg#compass-pin {
      position: absolute;
      // margin-top: -12.5px;
      // margin-left: -12.5px;
      path {
          fill: red !important;
      }
    }
}

.leaflet-container {
  // set project font to leaflet elements
  font: inherit;
  background-color: #e7e7e7;
}

.leaflet-popup-content-wrapper {
  box-shadow: none;
  color: black;
  border: $border-width * 2 solid $border-color;
  border-radius: 0;
  padding: 0;
}

// actual content of a popup
.leaflet-popup-content {
  margin: 0;
  h4 {
    padding: .25rem .5rem;
    background-color: $alt-color;
    color: white;

    &.wandering {
      background-color: $color;
    }
  }

  .popup-content {
    padding: 0 .5rem;
  }

  .card {
    min-width: 250px;
    padding: .5rem;
  }

  .btn {
    margin-top: 0.5rem;
    width: 100%;
  }

  p {
    margin: 0;
  }
}

// leaflet popup arrow
.leaflet-popup-tip {
  box-shadow: none;
  background-color: $color;
}
