* {
    box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, dl, dd, figure, input {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}
